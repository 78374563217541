<template>
  <v-card class="mx-auto pt-7 mb-7" min-height="80vh" width="80vw">
      <v-row justify="center">
        <v-btn x-small fab @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>
      <v-row justify="center">
        <v-card-title>
          Transaction Details
        </v-card-title>
      </v-row >
      <v-row justify="center" class="text-h6">Event updates</v-row>
      <v-row justify="center">
      <v-card width="70%" elevation="0" color="transparent">
        <v-textarea solo
        readonly v-model="currentDonation.description">
        </v-textarea>
        </v-card>
      </v-row>
      <v-row justify="center" v-if="isAppOwner">
          <v-btn  elevation="2" class="mb-4" v-if="!currentDonation.live"
              @click="donationStartStopWindow = true">
              <v-icon class="mx-1" color="success">
                mdi-restart
              </v-icon>
              Start Receiving Fund
          </v-btn>
          <v-btn  elevation="2" class="mb-4" v-else
              @click="donationStartStopWindow = true">
              <v-icon class="mx-1" color="warning">
                mdi-stop-circle-outline
              </v-icon>
              Stop Receiving Fund
          </v-btn>
          <v-dialog v-model="donationStartStopWindow" max-width="80%">
            <v-card class="pa-7 text-center" min-width="40%">
            <v-row justify="center" >Are you sure to process?</v-row>
            <v-alert dense outlined type="info" color="secondary" width="90%"
                class="text-body-2 mx-auto ">
              Only Live Fundraising/Donation events can receive donations
              <br>whereas the opposite cannot
            </v-alert>
            <v-row justify="center"><v-btn :loading="startstopLoading"
              @click="startOrStopDonationEvent">Yes</v-btn></v-row>
            </v-card>
          </v-dialog>
      </v-row>
      <v-row justify="center">
        <v-btn v-if="isAppOwner" @click="transactionState.windowOpened=true">
          Add transaction manually<v-icon>mdi-bank-transfer</v-icon>
        </v-btn>
        <v-btn v-else-if="currentDonation.live && loginConfirm" @click="transactionState.windowOpened=true">
          Donate <v-icon color="primary">mdi-charity</v-icon>
        </v-btn>
        <v-btn v-else-if="currentDonation.live" disabled>Login To Donate</v-btn>
      </v-row>
      <v-divider class="my-7 primary"></v-divider>
      <v-row v-if="isLoaded === false" justify="center" >
        No Transaction is done yet!<br>
        Be the first GREAT GIVER!
      </v-row>
      <v-card v-else class="mx-auto"
        width="80%">
        <v-data-table
          :loading="!isLoaded"
          :headers="tableHeaders"
          :items="tableData"
          :search="searchInput"
        >
        <template v-slot:top>
           <v-card class="mx-auto" color="transparent"
               elevation="0" width="80%">
            <v-row justify="center" >
              <v-text-field v-model="searchInput" hint="SEARCH" label="SEARCH Date, Amount, or ID">
              </v-text-field>
            </v-row>
           </v-card>
        </template>
        </v-data-table>
      </v-card>
    <v-dialog v-model="transactionState.windowOpened"
          :width="$vuetify.breakpoint.mobile ? '90%' : '50%'">
        <v-card class="pa-8 text-center">
          <v-card-title v-if="isAppOwner">
              <v-row justify="center">ADD TRANSACTION
                <v-icon color="primary" class="mx-2">mdi-bank-transfer</v-icon></v-row>
          </v-card-title>
          <v-card-title v-else>
              <v-row justify="center">Donation
                <v-icon color="primary">mdi-charity</v-icon></v-row>
          </v-card-title>
          <v-form ref="transactionForm">
            <v-text-field outlined prefix="MYR $" type="number"
              @change="formatToCurrency"
              v-model="inputValue"></v-text-field>
          </v-form>
            <v-btn class="mx-auto" @click="performTransaction"
                label="Donation Amount" hint="In currency formats"
                :loading="transactionState.loading">
              <span v-if="isAppOwner">Add</span>
              <span v-else>Proceed</span>
              </v-btn>
            <v-row justify='center' v-if="message.displayed">
              <span :class="`text-body-3 text-center ${message.color}--text`">
                <v-icon small :color="message.color">mdi-check-circle</v-icon>
                {{message.content}}
              </span>
            </v-row>
        </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import Vue from 'vue'
/**
 * Use Open source VUe-draggable for Web Drag and Drop
 * Reference: https://github.com/SortableJS/Vue.Draggable
 */
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'TransactionTable',
  components: {},
  props: ['openned'],
  data () {
    return {
      tableData: [],
      isLoaded: null,
      inputValue: '',
      transaction: {
        amount: ''
      },
      transactionState: {
        windowOpened: false,
        loading: false
      },
      message: {
        content: '',
        displayed: false,
        color: ''
      },
      donationStartStopWindow: false,
      startstopLoading: false,
      searchInput: '',
      tableHeaders: [
        {
          text: 'Transaction Date',
          value: 'date',
          align: 'center'
        },
        {
          text: '$ Transaction Amount (MYR)',
          value: 'amount',
          align: 'center'
        },
        {
          text: 'Donated By (Unique ID)',
          value: 'contributorsub',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('donationStore', ['currentDonation']),
    ...mapGetters('userStore', ['loginConfirm']),
    isAppOwner () {
      if (this.loginConfirm) {
        const email = Vue.$keycloak.tokenParsed.email
        const segment = Vue.$pathConverter.getFirstSegment()
        return email === segment
      }
      return false
    }
  },
  methods: {
    ...mapActions('donationStore', ['updateDonation', 'requestTransactions',
      'performTransctionManually', 'performTransactionWithStripe']),
    startOrStopDonationEvent () {
      this.startstopLoading = true
      this.currentDonation.live = !this.currentDonation.live
      this.updateDonation(this.currentDonation).finally(() => {
        this.startstopLoading = false
        this.donationStartStopWindow = false
      })
    },
    formatToCurrency (input) {
      const currency = parseInt(input).toFixed(2)
      // console.log(currency)
      // console.log(typeof currency)
      this.inputValue = currency
      this.transaction.amount = parseFloat(currency)
    },
    close () {
      // console.log('close')
      this.$emit('update:openned', false)
    },
    performTransaction () {
      if (this.isAppOwner) {
        this.manuallyAddTransaction()
          .then(() => {
            this.isLoaded = true
            this.tableData = this.currentDonation.transactions
          })
      } else {
        this.performStripeTransaction()
          .then(() => {
            this.isLoaded = true
            this.tableData = this.currentDonation.transactions
          })
      }
    },
    async manuallyAddTransaction () {
      this.transactionState.loading = true
      if (this.$refs.transactionForm.validate()) {
        return this.performTransctionManually(this.transaction)
          .then(() => {
            this.message.content = 'A transaction is successfully added'
            this.message.color = 'success'
            setTimeout(() => {
              this.message.displayed = false
              this.transactionState.windowOpened = false
              this.transactionState.loading = false
            }, 1000)
          }).catch(() => {
            this.message.content = 'Fails to add a transaction. Please try later.'
            this.message.color = 'error'
            this.transactionState.loading = false
          }).finally(() => {
            this.message.displayed = true
          })
      }
    },
    async performStripeTransaction () {
      this.transactionState.loading = true
      const redirect = `${window.location.href}?stripe=true`
      if (this.$refs.transactionForm.validate()) {
        return this.performTransactionWithStripe({
          transaction: this.transaction,
          redirect: redirect
        }).then((response) => {
          // console.log(response)
        })
      }
    }
  },
  created () {
    this.requestTransactions().then(() => {
      this.isLoaded = true
      this.tableData = this.currentDonation.transactions
    }).catch(() => {
      this.isLoaded = false
    })
  }
}
</script>

<template>
  <div v-if="isLoaded">
    <v-card  class="mx-auto my-auto" elevation="6"
        :width="$vuetify.breakpoint.mobile ? '95%' : '80%'">
      <v-card-title>
        <v-row justify="center" class="text-center text-h6 text-wrap">
          {{currentProject.title}}
        </v-row>
      </v-card-title>
        <v-row justify="center" v-if="isAppOwner">
          <v-btn  elevation="2" class="my-4"
              @click="deleteState.deleteWindowOpened = true">
              Delete <v-icon class="mx-1" color="warning">mdi-delete</v-icon>
          </v-btn>
          <v-dialog v-model="deleteState.deleteWindowOpened"
              @input="v => v || cleanMessage()">
            <v-card class="pa-7 text-center">
              <v-row justify="center" >Are you sure to delete?</v-row>
              <v-row justify="center"><v-btn :loading="deleteState.deleteLoading"
                @click="deleteProject">Proceed</v-btn></v-row>
              <v-row v-if="message.displayed" justify="center" :class="message.error">
                <span :class="`text-body-3 text-center ${message.color}--text`">
                  {{message.content}}
                </span>
              </v-row>
            </v-card>
          </v-dialog>
        </v-row>
    <v-tabs v-model="tabSwitch">
      <v-tab class="mx-auto text-wrap">
          Voluntary Work
      </v-tab>
      <v-tab class="mx-auto text-wrap">
          Donation Fundraising
      </v-tab>
    </v-tabs>
    <v-tabs-items touchless v-model="tabSwitch">
      <v-tab-item>
        <v-card  class="mx-auto" elevation="0" color ="transparent">
          <v-row justify="center" >
            <v-btn fab elevation="7" class="mb-2 mt-5" v-if="isAppOwner"
                @click="taskState.taskCreationWindowOpened= true">
              <v-icon color="primary">mdi-plus-circle-outline</v-icon>
            </v-btn>
          </v-row>
          <Kanban :tasks="currentTasks"></Kanban>
        </v-card>
        <v-dialog v-model="taskState.taskCreationWindowOpened" max-width="80%"
            @input="v => v || cleanMessage()">
          <v-card class="pa-8 text-center">
            <v-card-title>
                <v-row justify="center">Create and Assign <br> a new Task</v-row>
            </v-card-title>
            <v-form ref="taskForm">
            <NameField label="Task Title" hint="Give meaningful title for your contributor"
              isApplicable :value.sync="newTask.title" :rule ="titleRule">
            </NameField>
            <v-textarea solo label="Task Description" v-model="newTask.content"
              :rules="testAreaRule"></v-textarea>
            <v-autocomplete label="Assign a Executor" :loading="taskState.seachLoading"
              :search-input.sync="executorInput" :items="registeredCollaborators"
              item-text="email"  item-value="userId" v-model="selectedExecutor"
              cache-items :rules="autocompleteRule"></v-autocomplete>
            </v-form>
            <v-card-actions>
              <v-btn class="mx-auto mt-7" @click="createNewTask"
                :loading="taskState.taskcreationLoading">Create</v-btn>
            </v-card-actions>
              <v-row justify='center' v-if="message.displayed">
                <span :class="`text-body-3 text-center ${message.color}--text`">
                  <v-icon small :color="message.color">mdi-check-circle</v-icon>
                  {{message.content}}
                </span>
              </v-row>
          </v-card>
        </v-dialog>
      </v-tab-item>
      <v-tab-item>
        <v-card  class="mx-auto my-7" elevation="0" color ="transparent">
            <v-row justify="center" v-if="isAppOwner">
              <v-btn fab elevation="7" class="mb-4"
                @click="donationState.donationCreationWindowOpened = true">
                <v-icon color="primary">mdi-plus-circle-outline</v-icon>
            </v-btn>
          </v-row>
          <DonationDashboard></DonationDashboard>
        </v-card>
        <v-dialog v-model="donationState.donationCreationWindowOpened"
            @input="v => v || cleanMessage()"
            :width="$vuetify.breakpoint.mobile ? '90%' : '50%'">
          <v-card class="pa-8 text-center">
            <v-card-title>
                <v-row justify="center">Create a new Donation</v-row>
            </v-card-title>
            <v-form ref="donationForm">
            <NameField label="Donation Title" hint="Give meaningful title for your contributor"
              isApplicable :value.sync="newDonation.title" :rule ="titleRule">
            </NameField>
            <v-textarea solo label="Donation Description" v-model="newDonation.description"
              :rules="testAreaRule"></v-textarea>
            </v-form>
            <v-card-actions>
              <v-btn class="mx-auto mt-7" @click="createNewDonation"
                :loading="donationState.donationcreationLoading">Create</v-btn>
            </v-card-actions>
              <v-row justify='center' v-if="message.displayed">
                <span :class="`text-body-3 text-center ${message.color}--text`">
                  <v-icon small :color="message.color">mdi-information</v-icon>
                  {{message.content}}
                </span>
              </v-row>
          </v-card>
        </v-dialog>
      </v-tab-item>
    </v-tabs-items>
    </v-card>
    <v-dialog v-model="thankyouPage.opened">
      <v-card class="pa-7 text-center">
        <v-card-title>
        <v-row justify="center">
          <p style="word-break:normal">{{thankyouPage.title}}
            <v-icon class="mx-2" color="primary">mdi-hand-heart</v-icon>
          </p>
        </v-row>
        </v-card-title>
        <v-alert dense outlined :type="thankyouPage.status" color="success" width="90%"
            class="text-body-2 mx-auto ">{{thankyouPage.content}}
          <br>Use contact information in Home Page to contact us whenever you need.
        </v-alert>
        <v-row justify="center">
          <v-btn @click="thankyouPage.opened=false">
            OK
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
  <div v-else>
    <v-skeleton-loader type="card-avatar, list-item"  class="mx-auto my-10" loading
        max-height="50vh" max-width="80vw"></v-skeleton-loader>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Kanban from '@/components/kanban/Kanban.vue'
import DonationDashboard from '@/components/donation/DonationDashboard.vue'
import NameField from '@/components/fields/NameField.vue'
export default {
  components: {
    Kanban, NameField, DonationDashboard
  },
  data () {
    return {
      tabSwitch: null,
      isLoaded: false,
      isListLoaded: null,
      thankyouPage: {
        opened: false,
        title: '',
        content: '',
        status: 'success'
      },
      deleteState: {
        deleteWindowOpened: false,
        deleteLoading: false
      },
      taskState: {
        taskCreationWindowOpened: false,
        taskcreationLoading: false,
        searchLoading: false
      },
      donationState: {
        donationCreationWindowOpened: false,
        donationcreationLoading: false
      },
      message: {
        content: '',
        displayed: false,
        color: 'success'
      },
      executorInput: 'abc',
      selectedExecutor: null,
      newTask: {
        title: '',
        content: '',
        executor: ''
      },
      newDonation: {
        title: '',
        description: ''
      },
      titleRule: [(input) => {
        return (input && input.length <= 200) || 'Please input between 1 and 200 characters'
      }],
      testAreaRule: [(input) => {
        return (input && input.length <= 2000) || 'word limit between 1 and 2000 characters'
      }],
      autocompleteRule: [(input) => {
        return (input !== null) || 'Please pick one Executor'
      }]
    }
  },
  computed: {
    ...mapGetters('projectStore', ['currentProject', 'currentTasks']),
    ...mapGetters('appStore', ['registeredCollaborators']),
    ...mapGetters('userStore', ['loginConfirm']),
    isAppOwner () {
      if (this.loginConfirm) {
        const email = Vue.$keycloak.tokenParsed.email
        const segment = Vue.$pathConverter.getFirstSegment()
        return email === segment
      }
      return false
    }
  },
  methods: {
    ...mapActions('projectStore', ['requestTasksForCurrentProject', 'deleteCurrentProject',
      'createTask']),
    ...mapActions('appStore', ['requestRegisteredContributors']),
    ...mapMutations('projectStore', ['setCurrentProjectId']),
    ...mapActions('donationStore', ['createDonation']),
    cleanMessage () {
      this.message.content = ''
      this.message.displayed = false
    },
    deleteProject () {
      this.deleteState.deleteLoading = true
      this.deleteCurrentProject()
        .then(() => {
          this.$router.push({
            name: 'ProjectsView',
            params: {
              root: Vue.$pathConverter.getFirstSegment()
            }
          })
        }).catch((err) => {
          if (err) {
            this.message.content = 'Fails to delete. A project with donation event(s) cannot be deleted.'
            this.message.color = 'error'
            this.message.displayed = true
          }
        }).finally(() => {
          this.deleteState.deleteLoading = false
        })
    },
    createNewTask () {
      if (this.$refs.taskForm.validate()) {
        this.taskState.taskcreationLoading = true
        const task = {
          title: this.newTask.title,
          content: this.newTask.content,
          executor: this.newTask.executor
        }
        this.createTask(task).then(() => {
          this.message.content = 'A new task is created'
          this.message.color = 'success'
          this.message.displayed = false
          this.taskState.taskCreationWindowOpened = false
          this.taskState.taskcreationLoading = false
        }).catch(() => {
          this.message.content = 'Fails to create a new task. Please try later.'
          this.message.color = 'error'
          this.taskState.taskcreationLoading = false
        }).finally(() => {
          this.message.displayed = false
          this.newTask = {
            title: ' ',
            content: ' ',
            executor: ' '
          }
          this.selectedExecutor = ' '
        })
      }
    },
    createNewDonation () {
      if (this.$refs.donationForm.validate()) {
        this.donationState.donationcreationLoading = true
        this.createDonation(this.newDonation).then((response) => {
          this.message.content = 'A new task is created'
          this.message.color = 'success'
          this.donationState.donationcreationLoading = false
          this.donationState.donationCreationWindowOpened = false
          this.newDonation.title = ''
          this.newDonation.description = ''
        }).catch(() => {
          this.message.content = 'Fails to create a new task. Please try later.'
          this.message.color = 'error'
          this.donationState.donationcreationLoading = false
        }).finally(() => {
          this.message.displayed = true
        })
      }
    },
    init () {
      this.setCurrentProjectId(Vue.$pathConverter.getLastSegmentOf(window.location.href))
      this.requestTasksForCurrentProject().then(() => {
        this.isLoaded = true
        this.isListLoaded = true
      }).catch((err) => {
        // if no resource found or this happens before login refresh from keycloak
        if (err?.response?.status === 404 ||
            err?.response?.status === 400) {
          this.isLoaded = true
          this.isListLoaded = false
        } else {
          this.$router.push({
            name: 'Error',
            params: {
              root: Vue.$pathConverter.getFirstSegment()
            }
          })
        }
      })
    }
  },
  watch: {
    loginConfirm (newloginConfirm) {
      // as long as keycloak server is connected and refresh is completed
      if (newloginConfirm !== null) {
        // when user enters directly to this page,
        //  init() may perform earlier than keycloak asynchronous login
        // reinovke init() after the login is confirmed
        this.init()
      } else {
        this.$router.push({
          name: 'Error',
          params: {
            root: Vue.$pathConverter.getFirstSegment()
          }
        })
      }
    },
    executorInput () {
      this.taskState.taskcreationLoading = true
      this.requestRegisteredContributors().finally(() => {
        this.taskState.taskcreationLoading = false
      })
    },
    selectedExecutor (selcted) {
      // console.log('selected!', selcted)
      this.newTask.executor = selcted
      // console.log(this.newTask)
    }
  },
  /**
   * If this page is loaded from other page, init() is invoked here since
   *    the watch function will not be invoked
   */
  created () {
    // check if this is loaded from stripe payment page
    const params = new URLSearchParams(window.location.search)
    const fromStripe = params.get('stripe')
    if (fromStripe) {
      const status = params.get('status')
      this.thankyouPage.opened = true
      if (status === 'success') {
        this.thankyouPage.status = 'success'
        this.thankyouPage.title = 'Thank you for your KINDNESS.'
        this.thankyouPage.content = 'You may check the transaction on the Donation Page.'
      } else if (status === 'cancel') {
        this.thankyouPage.status = 'secondary'
        this.thankyouPage.title = 'You have successfully cancelled the Transaction'
        this.thankyouPage.content = 'You may restart the transaction again in th Donation Page.'
      }
    }
    if (this.loginConfirm !== null) {
      this.init()
    }
  }
}
</script>

<template>
  <div>
    <v-card v-if="isLoaded"  elevation="6" class="mr-12"
        :min-width="$vuetify.breakpoint.mobile ? '40vw' : '90%'" v-ripple
        @click="isOpened=true">
      <v-card-text>
        <v-row justify="start">
        {{taskAssignment.title}}
        </v-row>
      <v-row justify="end">
        <kbd :class="tagColor">{{contributor.lastName}}</kbd>
      </v-row>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-skeleton-loader
          type="list-item-three-line@2,list-item-two-line"
          loading class="mx-auto" max-height="80%" max-width="50%">
      </v-skeleton-loader>
    </v-card>
    <v-dialog v-model="isOpened" :width="$vuetify.breakpoint.mobile ? '85vw' : '50vw'" >
      <v-card class="mx-auto" elevation="6"
          :width="$vuetify.breakpoint.mobile ? '80vw' : '40vw'">
        <v-card-title>
          <v-row justify="end" class="mr-7">
            <kbd :class="color(taskAssignment.status)">{{taskAssignment.status}}</kbd>
          </v-row>
        </v-card-title>
        <v-card-subtitle >
          <v-row>Assigned To:</v-row>
          <v-list>
            <v-list-item  class="text-wrap">
              <v-list-item-avatar :color="avatarColor">
                <v-img v-if="contributor.picture" :src="contributor.picture"></v-img>
                <h1 v-else class="white--text">{{name.substring(0,2)}}</h1>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{name}}</v-list-item-title>
                <v-list-item-subtitle>{{contributor.email}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-subtitle>
        <v-card-text>
          <v-row>Description:</v-row>
        </v-card-text>
          <v-row class="ml-7"><p class="text-body-1">{{task.task.content}}</p></v-row>
        <v-card-text>
            <v-divider class="primary my-7" ></v-divider>
              <v-row justify="start">
              Last Update<br>
              {{taskAssignment.updatedDate}}
              </v-row>
            <v-row class="mt-6">
              <v-textarea outlined label="Comment to update the task"
              v-model="task.task.comment"
                :rules="testAreaRule" :readonly="!isPermitted"></v-textarea>
            </v-row>
            <v-card-subtitle >
              <v-row justify="end" align="center" class="mt-n7">
                  <v-btn v-if="isPermitted" @click = "addComment"
                   :loading="commentLoading">
                    Update  <v-icon color="primary" class="mx-2">mdi-comment</v-icon>
                  </v-btn>
              </v-row>
              <v-row justify="center" v-if="isOwner">
                <v-btn  elevation="2" class="mt-7" v-if="isOwner"
                  @click="deleteState.deleteWindowOpened = true">
                  Delete Task
                  <v-icon class="mx-1" color="warning">mdi-delete</v-icon>
                </v-btn>
                </v-row>
              <v-dialog v-model="deleteState.deleteWindowOpened">
                <v-card class="pa-7 text-center">
                  <v-row justify="center" >Are you sure to delete?</v-row>
                  <v-row justify="center"><v-btn :loading="deleteState.deleteLoading"
                    @click="deleteTaskCard">Proceed</v-btn></v-row>
                </v-card>
              </v-dialog>
            </v-card-subtitle>
            <v-card-actions>
          </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapMutations, mapGetters } from 'vuex'
export default {
  props: ['task'],
  data () {
    return {
      isLoaded: false,
      isOpened: false,
      deleteState: {
        deleteWindowOpened: false,
        deleteLoading: false
      },
      commentLoading: false,
      testAreaRule: [(input) => {
        return (input && input.length <= 2000) || 'word limit between 1 and 2000 characters'
      }]
    }
  },
  computed: {
    ...mapGetters('userStore', ['loginConfirm']),
    contributor () {
      return this.task.executor
    },
    taskAssignment () {
      return this.task.task
    },
    avatarColor () {
      if (this.task.executor.group === 'charity') {
        return this.$vuetify.theme.themes.light.charityAvatar
      } else {
        return this.$vuetify.theme.themes.light.giverAvatar
      }
    },
    tagColor () {
      if (this.task.executor.group === 'charity') {
        return this.$vuetify.theme.options.kanban.charity
      } else {
        return this.$vuetify.theme.options.kanban.giver
      }
    },
    color () {
      return (index) => {
        return this.$vuetify.theme.options.kanban[index]
      }
    },
    name () {
      return `${this.task.executor.firstName} ${this.task.executor.lastName}`
    },
    isPermitted () {
      if (this.loginConfirm) {
        const executorSub = this.task.task.executor
        const owner = Vue.$pathConverter.getFirstSegment()
        const userSub = Vue.$keycloak.tokenParsed.sub
        const userEmail = Vue.$keycloak.tokenParsed.email
        return userSub === executorSub || userEmail === owner
      } else {
        return false
      }
    },
    isOwner () {
      // console.log('app owber', this.loginConfirm)
      if (this.loginConfirm) {
        const userEmail = Vue.$keycloak.tokenParsed.email
        const owner = Vue.$pathConverter.getFirstSegment()
        return userEmail === owner
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions('projectStore', ['updateTask', 'deleteTask']),
    ...mapMutations('projectStore', ['addTask']),
    addComment () {
      const updatedTask = this.task
      this.commentLoading = true
      this.addTask(updatedTask)
      this.updateTask(updatedTask).finally(() => {
        this.commentLoading = false
      })
    },
    deleteTaskCard () {
      this.deleteState.deleteLoading = true
      this.deleteTask(this.task).then(() => {
        this.isOpened = false
      }).finally(() => {
        this.deleteState.deleteLoading = false
      })
    }
  },
  watch: {
    task (newValue) {
      if (newValue) {
        this.isLoaded = true
      }
    }
  },
  created () {
    if (this.task) {
      this.isLoaded = true
    }
  }
}
</script>

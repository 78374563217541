<template>
<div>
    <v-card  class="mx-auto " elevation="0">
      <v-card-title>
        <v-row justify="center">Voluntary Work Kanban</v-row>
      </v-card-title>
      <v-flex grow style="overflow:auto" >
        <div class="d-flex justify-space-between overflow-x-scroll">
          <v-card v-for ="(status, i) in kanban" elevation="14" min-height="40vh"
              :min-width="$vuetify.breakpoint.mobile ? '55vw' : '25vw'"
              :key="status.header" class="mx-6 mb-3">
            <v-row justify="center" class="mb-4  text-h5 text-center">
              <kbd :class="color(i)">{{status.header}}</kbd>
            </v-row>
            <draggable :list="status.tasks" group="status.tasks" @change ="updateStatus(i, $event)"
                :animation="99" :move="isPermitted" class="ml-7">
              <KanbanCard v-for="task in status.tasks" :key="task.task.taskid"
                :task="task" class="my-5">
              </KanbanCard>
            </draggable>
          </v-card>
        </div>
      </v-flex>
    </v-card>
</div>
</template>
<script>
import Vue from 'vue'
import KanbanCard from '@/components/kanban/KanbanCard.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
/**
 * Use Open source Vue-draggable for Web Drag and Drop
 * Reference: https://github.com/SortableJS/Vue.Draggable
 */
import draggable from 'vuedraggable'
export default {
  name: 'Kanban',
  components: {
    draggable, KanbanCard
  },
  props: ['tasks'],
  data () {
    return {
      kanban: {
        new: {
          header: 'NEW',
          tasks: []
        },
        accepted: {
          header: 'ACCEPTED',
          tasks: []
        },
        in_progress: {
          header: 'IN PROGRESS',
          tasks: []
        },
        completed: {
          header: 'COMPLETED',
          tasks: []
        },
        ended: {
          header: 'ENDED',
          tasks: []
        }
      }
    }
  },
  computed: {
    ...mapGetters('projectStore', ['currentAddedTask', 'newlyDeletedTask']),
    ...mapGetters('userStore', ['loginConfirm']),
    color () {
      return (index) => {
        return this.$vuetify.theme.options.kanban[index]
      }
    },
    // allow Vue to cache this for better performance
    ownerEmail () {
      return Vue.$pathConverter.getFirstSegment()
    },
    userEmail () {
      return Vue.$keycloak.tokenParsed.email
    },
    userSub () {
      return Vue.$keycloak.tokenParsed.sub
    }

  },
  methods: {
    ...mapActions('projectStore', ['requestTasksForCurrentProject', 'updateTask']),
    ...mapMutations('projectStore', ['addTask']),
    isPermitted (event) {
      if (this.loginConfirm) {
        const executorSub = event.draggedContext.element.task.executor

        return this.userSub === executorSub || this.userEmail === this.ownerEmail
      } else {
        return false
      }
    },
    updateStatus (status, event) {
      if ('added' in event) {
        const index = event.added.newIndex
        const movedTask = this.kanban[status].tasks[index]
        movedTask.task.status = status
        this.addTask(movedTask)
        // console.log(movedTask)
        this.updateTask(movedTask)
      }
    }
  },
  watch: {
    currentAddedTask (newTask) {
      if (newTask) {
        // console.log('add task! ', newTask)
        this.kanban[newTask.task.status].tasks.push(newTask)
      }
    },
    newlyDeletedTask (deletedTask) {
      if (deletedTask) {
        // console.log('delete task!', deletedTask)
        const index = this.kanban[deletedTask.task.status].tasks.findIndex((task) => {
          return task.task.taskId === deletedTask.task.taskId
        })
        this.kanban[deletedTask.task.status].tasks.splice(index, 1)
      }
    }
  },
  created () {
    if (this.tasks) {
      Object.values(this.tasks).forEach((task) => {
        this.kanban[task.task.status].tasks.push(task)
      })
    }
  }
}
</script>

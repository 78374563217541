<template>
<div>
  <v-card  class="mx-auto " elevation="0" color="transparent">
    <v-card-title>
      <v-row justify="center">Donation Dashboard</v-row>
    </v-card-title>
    <v-card-text v-if="isLoaded">
      <v-simple-table>
        <template v-slot>
          <thead>
            <tr>
            <th>Fundraising</th>
            <th>SUM of Amount</th>
            <th v-if="isAppOwner">Live</th>
            <th v-else>Opening</th>
            </tr>
          </thead>
          <tbody>
        <tr v-for="donation in donations" :key="donation.donationId"
         color="transparent" elevation="0" @click="navigate(donation.donationId)">
        <td >
          <span class="text-wrap">
            {{donation.title}}
          </span>
        </td>
        <td>
        {{donation.sum}}
        </td>
        <td>
          <v-icon v-if="donation.live" color="primary">mdi-checkbox-marked-circle-outline</v-icon>
          <v-icon v-else color="warning">mdi-close-circle-multiple-outline</v-icon>
        </td>
        <td>
            <v-icon color="primary">mdi-arrow-right-bold-hexagon-outline</v-icon>
        </td>
        </tr>
        </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <div v-else-if="isLoaded === null">
      <v-skeleton-loader
          type="list-item-three-line@2, list-item-two-line"
          loading class="mx-auto" max-height="80%"
          max-width="50%"></v-skeleton-loader>
    </div>
    <div v-if="isLoaded===false" >
      <v-row justify="center" class="py-7"> No Donation/Fundraising event is created
      </v-row>
    </div>
  </v-card>
  <v-dialog v-model="openCurrentDonationDialog"
        max-width="90vw" >
      <TransactionTable
      :openned.sync="openCurrentDonationDialog"></TransactionTable>

  </v-dialog>
</div>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import TransactionTable from '@/components/donation/TransactionTable.vue'
export default {
  name: 'Kanban',
  components: { TransactionTable },
  data () {
    return {
      isLoaded: null,
      openCurrentDonationDialog: false
    }
  },
  computed: {
    ...mapGetters('donationStore', ['donations']),
    ...mapGetters('userStore', ['loginConfirm']),
    isAppOwner () {
      if (this.loginConfirm) {
        const email = Vue.$keycloak.tokenParsed.email
        const segment = Vue.$pathConverter.getFirstSegment()
        return email === segment
      }
      return false
    }
  },
  methods: {
    ...mapActions('donationStore', ['requestOwnDonations',
      'requestDonations']),
    ...mapMutations('donationStore', ['setCurrentDonationId', 'cleanDonations']),
    navigate (donationId) {
      this.setCurrentDonationId(donationId)
      this.openCurrentDonationDialog = true
    },
    init () {
      this.cleanDonations()
      if (this.isAppOwner) {
        this.requestOwnDonations().then(() => {
          this.isLoaded = true
        }).catch(() => {
          this.isLoaded = false
        })
      } else {
        this.requestDonations().then(() => {
          this.isLoaded = true
        }).catch((err) => {
          if (err.response.status === 404) {
            this.isLoaded = false
          }
        })
      }
    }
  },
  watch: {
    loginConfirm (newLoginConfirm) {
      if (newLoginConfirm !== null) {
        this.init()
      }
    },
    donations: {
      deep: true,
      handler (newDonations) {
        // console.log(newDonations)
        if (newDonations) {
          this.isLoaded = true
        }
      }
    }
  },
  created () {
    if (this.loginConfirm !== null) {
      this.init()
    }
    // console.log(this.donations)
  }
}
</script>
